.menu-buttons-container {
	// border-top: 1px solid white;
	margin-top: 2rem;
	position: relative;
	[class*="col-"] {
		padding-bottom: 1.8rem;
		padding-top: 1.8rem;
	}
	[class*="col-"]:first-of-type:before {
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#cec7ba+0,cec7ba+100&0+0,0.5+0,0+100 */
		background: -moz-linear-gradient(top, rgba(206,199,186,0.5) 0%, rgba(206,199,186,0) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(206,199,186,0.5) 0%,rgba(206,199,186,0) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(206,199,186,0.5) 0%,rgba(206,199,186,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80cec7ba', endColorstr='#00cec7ba',GradientType=0 ); /* IE6-9 */
		content: "";
		display: inline-block;
		height: 100%;
		right: 0;
		position: absolute;
		top: 0;
		width: 1px;
	}
	&:before {
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#cec7ba+0,cec7ba+50,cec7ba+100&0+0,0.5+50,0+100 */
		background: -moz-linear-gradient(left, rgba(206,199,186,0) 0%, rgba(206,199,186,0.5) 50%, rgba(206,199,186,0) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left, rgba(206,199,186,0) 0%,rgba(206,199,186,0.5) 50%,rgba(206,199,186,0) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right, rgba(206,199,186,0) 0%,rgba(206,199,186,0.5) 50%,rgba(206,199,186,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00cec7ba', endColorstr='#00cec7ba',GradientType=1 ); /* IE6-9 */
		content: "";
		display: inline-block;
		height: 1px;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
}

.menu-item {
	margin-bottom: 2rem;
	display: flex;
}

.menu-item__title {
	color: white;
	font-size: 1.5rem;
	letter-spacing: 0.2rem;
	margin-top: 0.375rem;
	text-align: left;
}

.menu-item__line {
	background: url(../images/menu-line.png) center right no-repeat;
	flex: 1;
	margin: 0 1.1rem;
}

.menu-item__price {
	color: $blue;
	font-size: 2rem;
	letter-spacing: 0.2rem;
	text-align: right;
	sup {
		color: white;
		top: -0.15em;
	}
}