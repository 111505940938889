button {
	background: none;
	border: none;
	outline: none;
}

.button {
	background: $blue;
	color: white;
	font-family: $font-stack-alt;
	font-size: 1.2rem;
	line-height: 1;
	min-height: 4.4rem;
	min-width: 4.4rem;
	opacity: 1;
	padding: 1.5rem 1.5rem;
	transition: all 200ms ease;
	vertical-align: middle;
	&.disabled {
		opacity: 0.5;
	}
	&:hover {
		background: $black-blue;
	}
	&.less {
		background: $black-blue url(../images/arrow-left.png) center center / 1.9rem auto no-repeat;
		&:hover {
		    background: $blue url(../images/arrow-left.png) center center / 1.9rem auto no-repeat;
		}
	}
	&.more {
		background: $black-blue url(../images/arrow-right.png) right 1rem center / 1.9rem auto no-repeat;
		padding-right: 4rem;
	  	&:hover {
	    	background: $blue url(../images/arrow-right.png) right 1rem center / 1.9rem auto no-repeat;
	  	}
	  	@media screen and (max-width: 767px) {
			font-size: 0;
			padding-right: 1.5rem;
			background: $black-blue url(../images/arrow-right.png) center center / 1.9rem auto no-repeat;
		  	&:hover {
		    	background: $blue url(../images/arrow-right.png) center center / 1.9rem auto no-repeat;
		  	}
		}
	}
}


.button-pager {
	color: white;
	letter-spacing: 0.2rem;
	left: 50%;
	font-size: 1.2rem;
	font-weight: bold;
	line-height: 1;
	position: absolute;
    transform: translateX(-50%);
	text-transform: uppercase;
	transition: all 250ms ease;
	z-index: 25;
	&.bottom {
		bottom: 5rem;
		svg {
			margin: 0 auto 1.5rem;
		}
	}
	&.top {
		top: 5rem;
		svg {
			margin: 1.5rem auto 0;
		}
	}
	svg {
		display: block;
		fill: white;
		transition: all 250ms ease;
	}
	.icon-oyster {
		height: 2.1rem;
		width: 3.825rem;
	}
	.icon-lifebud {
		height: 3rem;
		width: 3rem;
	}
	.icon-crate {
		height: 2.25rem;
		width: 3.9rem;
	}
	.icon-anchor {
		height: 3.3rem;
		width: 3rem;
	}
	&:hover {
		color: $blue;
		svg {
			fill: $blue;
		}
	}
}

.button-reservation {
	background: url(../images/background-button.png) center center / cover no-repeat;
	color: white;
	padding: 1.875rem 2rem;
	opacity: 0.9;
	transition: opacity 200ms ease;
	span {
		color: $blue;
	}
	&.fixed {
		position: fixed;
		right: 6.6rem;
		top: 6.6rem;
		z-index: 1;
		@media screen and (max-width: 1199px) {
			right: 5rem;
			top: 5rem;
		}
		@media screen and (max-width: 767px) {
			background: none;
		    font-size: 13px;
		    padding: 0;
		    right: 15px;
		    top: 15px;
		}
	}
	&:hover {
		color: white;
		text-decoration: none;
		opacity: 1;
	}
}

.button-download {
	color: $blue;
	display: inline-block;
	font-family: $font-stack-alt;
	font-size: 1.35rem;
	padding: 1rem 1.5rem;
	&:after {
		background: url(../images/icon-download.png) center center/ 100% auto no-repeat;
		content: "";
		display: inline-block;
		height: 1.3rem;
		margin-left: 1.5rem;
		vertical-align: middle;
		width: 1.5rem;
	}
	&:hover {
		color: white;
		text-decoration: none;
	}
}