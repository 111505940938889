html,
button,
input,
select,
textarea {
	color: $color-text;
	font-family: $font-stack;
}

html {
	font-size: 10px;
	@media screen and (min-width: 1441px){
		font-size: 11px;	
	}
	@media screen and (min-width: 1601px){
		font-size: 12px;	
	}
	/*@media screen and (max-width: 1440px){
		font-size: 9px;	
	}
	@media screen and (max-width: 1280px){
		font-size: 8px;	
	}
	@media screen and (max-width: 991px){
		font-size: 7px;	
	}
	@media screen and (max-width: 767px){
		font-size: 6px;	
	}*/
}

body {
	background: #fff;
	color: $color-text;
	font-family: $font-stack;
	font-size: $font-size-base;
	line-height: $line-height-base;
	margin: 0;
	text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @media screen and (max-width: 991px) {
		background: url(../images/bg-home.jpg) center center no-repeat;
		background-size: cover;
		background-attachment: fixed;
	}
}

a {
	color: $color-link;
	text-decoration: none;
	&:hover { color: $color-link-hover; }
	&:focus,
	&:active { color: $color-link-active; }
}

img { vertical-align: middle; max-width: 100%; }

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}

fieldset {
	border: 0;
	margin: 0; padding: 0;
}

textarea {
	resize: vertical;
}

// Selection
::-moz-selection {
	background: black;
	color: white;
	text-shadow: none;
}

::selection {
	background: black;
	color: white;
	text-shadow: none;
}

// Placeholder color
::-webkit-input-placeholder { /* WebKit browsers */
	color: black;
	opacity: 1;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	color: black;
	opacity: 1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
	color: black;
	opacity: 1;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
	color: black;
	opacity: 1;
}
