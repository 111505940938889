.fixed-copyright {
	bottom: 6.6rem;
	color: $pastel-blue;
	font-size: 0.9rem;
	font-weight: bold;
	letter-spacing: 0.2rem;
	position: fixed;
	right: 6.6rem;
	text-transform: uppercase;
	@media screen and (max-width: 1199px) {
		bottom: 5rem;
		right: 5rem;
	}
	@media screen and (max-width: 991px) {
		position: static;
		text-align: center;
	}
}

.fixed-logo {
	display: inline-block;
	left: 6.6rem;
	position: fixed;
	top: 6.6rem;
	z-index: 1;
	img {
		height: auto;
		width: 11rem;
	}
	@media screen and (max-width: 1199px) {
		top: 5rem;
		left: 5rem;
	}
	@media screen and (max-width: 767px) {
		top: 15px;
		left: 15px;
		img {
			height: 40px;
			width: auto;
		}
	}
}

.fixed-menu {
	position: fixed;
	left: 6.6rem;
	top: 50%;
	transform: translateY(-50%);
	z-index: 10;
	a {
		color: white;
		display: block;
		font-size: 1.2rem;
		letter-spacing: 0.2rem;
		line-height: 1;
		padding: 1.2rem 0 1.2rem 3.375rem;
		position: relative;
		text-transform: uppercase;
		&:before {
			background: url(../images/menu-knife.png) center center / 6.6rem auto no-repeat;
			content: "";
			height: 1.3rem;
			margin-top: -0.8rem;
			opacity: 0;
			position: absolute;
			left: 0;
			top: 50%;
			width: 6.6rem;
			transform: translateX(-100%);
			transition: all 250ms ease 50ms;
		}
		.text {
			color: white;
			display: inline-block;
			transition: all 250ms ease;
			transform: translateX(0);
		}
		.number {
			font-size: 0.9rem;
			font-weight: 300;
			left: 0;
			opacity: 1;
			position: absolute;
			top: 1.3rem;
			text-decoration: line-through;
			transition: opacity 200ms ease;
		}
		&:hover, &.active {
			&:before {
				opacity: 1;
				transform: translateX(0);
			}
			.text {
				color: $blue;
				text-decoration: none;
				transform: translateX(5.25rem);
			}
			.number {
				opacity: 0;
			}
		}
	}
	@media screen and (max-width: 1199px) {
		left: 5rem;
		a {
			&:before {
				background: url(../images/menu-knife.png) center center / 4.5rem auto no-repeat;
				width: 4.5rem;
			}
			&:hover, &.active {
				.text {
					transform: translateX(3.25rem);
				}
			}
		}
	}
}

.fixed-background-menu-mobile {
	background: rgba(8, 22, 35, 0.9);
	height: 70px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1;
	-webkit-box-shadow: 0 0 5px 5px rgba(8,22,35,0.3);
	box-shadow: 0 0 5px 5px rgba(8,22,35,0.3);
}