/* =======================================================================
Responsive
========================================================================== */
$breakpoints: (
	'xsmall': 		(max-width: 479px),
	'small': 		(max-width: 767px),
	'medium': 		(max-width: 992px),
	'large': 		(max-width: 1199px),
	'xxl': 			(max-width: 1439px)
);


/* =======================================================================
Font-face
========================================================================== */

// @font-face {
// 	font-family: 'font-name-here';
// 	src: url('../fonts/font-name-here/font-name-here.ttf') format("truetype");
// }

/* @font-face kit by Fonts2u (http://www.fonts2u.com) */
@font-face {
	font-family:"OswaldStencil-Bold";
	src:url("../fonts/Oswald-Stencil.eot?") format("eot"),
	url("../fonts/Oswald-Stencil.woff") format("woff"),
	url("../fonts/Oswald-Stencil.ttf") format("truetype"),
	url("../fonts/Oswald-Stencil.svg#OswaldStencil-Bold") format("svg");
	font-weight:normal;
	font-style:normal;
}

@import url('https://fonts.googleapis.com/css?family=Varela+Round');


/* =======================================================================
Variables
========================================================================== */

// Colors
$main-color: 			  #ff0000;

$pastel-blue: 			  #5f83a4;
$blue: 			  		  #3183fe;
$black-blue: 			  #081623;

// Fonts stacks
$font-stack:              "OswaldStencil-Bold";
$font-stack-alt:          'Varela Round', sans-serif;
$font-size-base:          16px;
$line-height-base:        1.4;

// Typography
$color-text:              white;
$color-link:              $main-color;
$color-link-visited:      lighten($color-link, 20%);
$color-link-hover:        darken($color-link, 20%);
$color-link-active:       darken($color-link, 40%);
