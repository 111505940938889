.opening-hours {
	font-family: $font-stack-alt;
}

.opening-hours__row {
	font-size: 0;
	margin-bottom: 1.1rem;
	span {
		display: inline-block;
		font-size: 1.2rem;
		vertical-align: middle;
		&:first-of-type {
			width: 7.5rem;
		}
		&:last-of-type {
		}
	}
	.separator {
		background: white;
		height: 1px;
		margin: 0 2rem 0 1.5rem;
		width: 4.5rem;
	}
}