.socialmedia-fixed {
	position: fixed;
	right: 6.6rem;
	top: 50%;
	transform: translateY(-50%);
	z-index: 10;
	@media screen and (max-width:767px) {
		right: 15px;
		top: 50px;
	}
}
.socialmedia-link {
	display: block;
	margin: 0 auto 2rem;
	opacity: 0.8;
	text-align: center;
	transition: opacity 200ms ease;
	&:last-of-type {
		margin-bottom: 0;
	}
	&:hover {
		opacity: 1;
	}
	@media screen and (max-width:767px) {
		display: inline-block;
		margin: 0 0 0 20px;
	}
}

.socialmedia-facebook {
	background: url(../images/icon-facebook.png) center center / .7rem auto no-repeat;
	height: 1.4rem;
	width: .7rem;
}

.socialmedia-instagram {
	background: url(../images/icon-instagram.png) center center / 1.3rem auto no-repeat;
	height: 1.3rem;
	width: 1.3rem;
}

.socialmedia-mail {
	background: url(../images/icon-mail.png) center center / 1.4rem auto no-repeat;
	height: 1.1rem;
	width: 1.4rem;
}