.content {
	text-align: left;
	h2 {
		color: $pastel-blue;
		font-size: 1.2rem;
		letter-spacing: 0.2rem;
		text-transform: uppercase;
		margin: 0 0 2rem;
	}
	p {
		font-family: $font-stack-alt;
		font-size: 1.2rem;
		line-height: 1.6;
		margin: 2rem 0;
	}
	hr {
		background: url(../images/menu-line.png) center left no-repeat;
		border: none;
		height: 11px;
		margin: 2rem 0;
	}
	a{
		color: $blue;
	}
	.small {
		display: inline-block;
		font-size: .9rem;
		line-height: 1.2;
	}
}