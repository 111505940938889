.section__contour {
	font-size: 0.625em;
	height: 100%;
	left: 0;
	position: fixed;
	pointer-events: none;
	top: 0;
	width: 100%;
	z-index: 1;
	div {
		position: absolute;
	}
	.top {
		background: url(../images/bg-contour-top.png) center center / auto 4.2em no-repeat;
		top: 0;
		left: 0;
		width: 100%;
		height: 4.2em;
		z-index: 1;
	}
	.bottom {
		background: url(../images/bg-contour-bottom.png) center center / auto 3.9em no-repeat;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 3.9em;
		z-index: 1;
	} 
	.left {
		background: url(../images/bg-contour-left.png) center center / 4.2em auto no-repeat;
		top: 0;
		left: 0;
		width: 4.2em;
		height: 100%;
	}
	.right {
		background: url(../images/bg-contour-right.png) center center / 3.9em auto no-repeat;
		top: 0;
		right: 0;
		width: 3.9em;
		height: 100%;
	}
	@media screen and (min-width: 1401px){
		font-size: 0.75em;	
	}
	@media screen and (min-width: 1601px){
		font-size: 0.85em;
	}
	@media screen and (max-width: 1199px){
		font-size: 0.6em;
	}
}

.section {
	.container {
		padding: 3.9rem 15px;
		position: relative;
		z-index: 10;
	}
	@media screen and (max-width: 991px) {
		background: none !important;
	}
}

.section-home {
	background: url(../images/bg-home.jpg) center center / cover no-repeat;
	text-align: center;
	.logo {
		max-width: 45rem;
		width: 100%;
	}

	.menu_emporter {
	    font-size: 4rem;
	    margin: -2rem auto 0 auto;
	    letter-spacing: 5px;
        display: block;
    	max-width: 700px;
    	line-height: 1.2;
	}

	.button-reservation {
		margin-top: 4rem;
		display: inline-block;
		//padding: 1.875rem 2rem 3rem;
	}
}

.section-menu {
	background: url(../images/bg-menu-with-image.jpg) center center / cover no-repeat;
	text-align: center;
	@media screen and (max-width: 991px) {
		background: url(../images/bg-resto-with-image.jpg) center center / cover no-repeat;
	}
}

.section-resto {
	.background-half {
		background: url(../images/bg-left-resto.jpg) right center / cover no-repeat;
		height: 100%;
		left: 0;
		position: absolute;
		pointer-events: none;
		top: 0;
		width: 60%;
		z-index: 8;
		&:after {
			background: url(../images/bg-right-resto.jpg) left center / cover no-repeat;
			content: "";
			content: "";
		    right: -14.1%;
		    height: 100%;
		    position: absolute;
		    width: 14.1%;
		}
	}
	@media screen and (max-width: 991px) {
		background: url(../images/bg-resto-with-image.jpg) center center / cover no-repeat;
		.background-half {
			display: none;
		}
	}
}

.section-about {
	background: url(../images/bg-about.jpg) center center / cover no-repeat;
}

.section-location {
	background: url(../images/bg-location.jpg) center center / cover no-repeat;
	.section-location-tagline {
		color: white;
		font-size: 23px;
		line-height: 1.8;
	}
}

.section__title {
	color: white;
	font-size: 7rem;
	letter-spacing: 1rem;
	line-height: 1;
	margin: 0 0 4rem 0;
	@media screen and (max-width: 991px) {
		font-size: 5rem;
	}
	@media screen and (max-width: 767px) {
		font-size: 4rem;
		letter-spacing: 0.3rem;
		margin: 0 0 3rem 0;
	}
}