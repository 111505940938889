.tab-container {
	height: 30vh;
	overflow: auto;
}

.tab-content {
	display: none;
	.menu-item {
		transform: translateY(-30px);
		opacity: 0;
		transition: all 200ms ease;
	}
	&.active {
		display: block;
		.menu-item {
			transform: translateY(0);
			opacity: 1;
		}
	}
}

.tab-menu {
	font-size: 0;
	margin-bottom: 3rem;
	button {
		background: transparent;
		border: 1px solid $pastel-blue;
		color: white;
		display: inline-block;
		letter-spacing: 0.2rem;
		font-size: 1.3rem;
		padding: 1.2rem 1.8rem;
		outline: none;
		transition: all 200ms ease;
		&:hover {
			background: rgba(95, 131, 164, 0.3);
		}
		&.active {
			background: $blue;
			border: 1px solid $blue;
		}
	}
	@media screen and (max-width: 767px) {
		button {
			width: 50%;
		}
	}
}